import { FRONTEND_URL, BACKEND_URL } from '../Constants';

/**
 * Refactor urls from API
 *
 * @param {string} url The default url
 * @returns {string} The refactored url.
 */
export function refactorUrl(url) {
  let strippedUrl;

  if (url && url.includes(FRONTEND_URL)) {
    return (strippedUrl = url.replace(FRONTEND_URL, ''));
  }
  if (url && url.includes(BACKEND_URL)) {
    // eslint-disable-next-line no-unused-vars
    return (strippedUrl = url.replace(BACKEND_URL, ''));
  }

  return url;
}
