import React from 'react';
import TimeStamp from '../time/TimeStamp';
import CategoriesList from '../categories/CategoriesList';
import { Link } from 'react-router-dom';
import Icon from '../icons/Icon';

const EXCERPT_MAXLENGTH = 120;

/**
 * @typedef {object} PostProps
 * @property {import('../Callidus').SparsePost} post The post to display
 * @property {string} [className] Optional base class-name
 */

/**
 * @typedef {object} BaseProps
 * @property {import('../Callidus').SparsePost} post The post to display
 * @property {string} className Base class-name
 * @property {JSX.Element | JSX.Element[]} children Nested content
 */

/**
 * @param {BaseProps} arg React props
 * @returns {JSX.Element} An `article` representing a single post.
 */
export function SpareBaseBlock({ post, className, children }) {
  const postStyle = {
    backgroundImage: `url('${
      post._embedded?.['wp:featuredmedia']?.[0].source_url
    }')`,
  };

  const baseClass = className || 'posts-list';

  return (
    <Link
      className={ `${baseClass}__post` }
      to={ `/${post.type}/${post.slug}` }
    >
      <CategoriesList post={ post } />
      <div className={ `${baseClass}__media` } style={ postStyle } />
      <h3 className={ `${baseClass}__title` } dangerouslySetInnerHTML={ {
        __html: post.title.rendered,
      } } />
      { children }
    </Link>
  );
}

/**
 * @param {import('../Callidus').SparsePost} post A post
 * @returns {string} Excerpt truncated at `MAX_LENGTH`
 */
function getTruncatedExcerpt(post) {
  const excerpt = post.excerpt?.rendered || '';
  if (excerpt.length > EXCERPT_MAXLENGTH) {
    // Cut to max length
    const truncated = excerpt.slice(0, EXCERPT_MAXLENGTH);
    // Cut to last white-space
    return truncated.substr(0, truncated.lastIndexOf(' ')).concat('&nbsp;...');
  }
  return excerpt;
}

/**
 * Show a listing of a single post
 *
 * @param {PostProps} arg React props
 * @returns {JSX.Element} An `article` representing a single post.
 */
export default function SparsePostBlock({ post, className }) {
  const baseClass = className || 'posts-list';

  const excerpt = getTruncatedExcerpt(post);

  return (
    <SpareBaseBlock
      key={ post.id }
      className={ baseClass }
      post={ post }
    >
      <div className={ `${baseClass}__excerpt` } dangerouslySetInnerHTML={ {
        __html: excerpt,
      } } />
      <TimeStamp date={ post.date } className={ `${baseClass}__timestamp` }>
        <Icon icon="icon-time" />
      </TimeStamp>
      <span className={ `${baseClass}__opened-count` }>
        <Icon icon="icon-view" />0
      </span>
      <span className={ `${baseClass}__likes-count` }>
        <Icon icon="icon-thumbs-up" />0
      </span>
      <span className={ `${baseClass}__comment-count` }>
        <Icon icon="icon-chat" />0
      </span>
    </SpareBaseBlock>
  );
}
