import React from 'react';
import DefaultView from './DefaultView';
import useContacts from '../contacts/useContacts';

/**
 * @param {{
 *   contact: import('../Callidus').SparseContact,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
function Contact({ contact }) {
  const line1 = [
    contact.callidus_location_address_1,
    contact.callidus_location_address_2,
    contact.callidus_location_address_3,
  ].filter((l) => !!l).join(', ');
  const line2 = [
    contact.callidus_location_zip,
    contact.callidus_location_country,
  ].filter((l) => !!l).join(', ');
  return <article className="contacts-list__contact">
    <h3 className="contacts-list__title">{
      contact.callidus_location_title
    }</h3>
    <p className="contacts-list__adress1">{ line1 }</p>
    <p className="contacts-list__adress2">{ line2 }</p>
    { contact.callidus_location_phone && (
      <a
        className="contacts-list__phone"
        href={ `tel:${contact.callidus_location_phone}` }
      >{ contact.callidus_location_phone }</a>
    ) }
    { contact.callidus_location_fax && (
      <a
        className="contacts-list__fax"
        href={ `tel:${contact.callidus_location_fax}` }
      >{ contact.callidus_location_fax }</a>
    ) }
  </article>;
}

/**
 * @param {{
 *   country: string,
 *   contacts: import('../Callidus').SparseContact[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
function Country({ country, contacts }) {
  const countryContacts = contacts.filter(
      (contact) => contact.callidus_location_country === country,
  );
  return (
    <li className="countries-list__country">
      <h2 className="countries-list__title">{ country }</h2>
      <ol className="contacts-list">
        { countryContacts.map((contact) => (
          <Contact
            contact={ contact }
            key={ contact.callidus_location_zip }
          />
        )) }
      </ol>
    </li>
  );
}

/**
 * @param {{
 *   page: import('../Callidus').Page,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function ContactView({ page }) {
  const [ contacts ] = useContacts(page);

  const countries = contacts.reduce((acc, contact) => (
    acc.includes(contact.callidus_location_country) ? acc : [
      ...acc,
      contact.callidus_location_country,
    ]
  ), []);

  return <DefaultView page={ page }>
    <ol className="countries-list">
      { countries.map((country) => (
        <Country country={ country } contacts={ contacts } key={ country } />
      )) }
    </ol>
  </DefaultView>;
}
