import { useEffect, useState } from 'react';

const contactCache = [];
/**
 * Basic hook to fetch Contacts from the page object, de-serialise and cache.
 *
 * @param {import('../Callidus').Page} page A WordPress page object
 * @returns {Array<import('../Callidus').SparseContact[]>}
 *          returns the `contacts` state.
 */
export default function useContacts(page) {
  const [ contacts, setContacts ] = useState(contactCache);

  useEffect(() => {
    // Using a poor man's deserialiser to go from PHP-serialised to JSON
    const cleanFields = `[${
      page.callidus_custom_fields.callidus_contact_data[0]
          // strip control chars
          .replace(/([a-z]:[0-9]+(:|;))+/gi, '\n')
          // create key-value pairs
          .replace(/("[^"]*");\n?("[^"]*");\n?/gi, '$1:$2,\n')
          // Add separators
          .replace(/,\n\}/g, '},')
          // Trim start/end junk
          .slice(2, -2)
    }]`;
    const callidusCustomField = JSON.parse(
        cleanFields,
    );
    setContacts(callidusCustomField);
  }, [ page.callidus_custom_fields ]);

  return [ contacts ];
}
