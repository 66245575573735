import React from 'react';
import { useParams } from 'react-router';
import ContactView from './ContactView';
import DefaultView from './DefaultView';
import FeedView from './FeedView';
import usePage from './usePage';

const VIEW_TYPES = {
  'feed': FeedView,
  'contact': ContactView,
  'default': DefaultView,
};

/**
 * @returns {JSX.Element|null} ...
 */
export default function PageView() {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();

  const page = usePage(slug);

  if (page === null) return null;

  const ViewType = VIEW_TYPES[page.template] || VIEW_TYPES['default'];

  return <ViewType page={ page } />;
}
