import React from 'react';
import useAuthUser from './auth/useAuthUser';
import PostsList from './posts/PostsList';

/**
 * @returns {JSX.Element} React Component
 */
export default function HomeView() {
  const user = useAuthUser();
  // We're in an authenticated view, the user will resolve, until then
  // we cannot access given_name...
  // (CSS placeholder is used , so that we don't get jumping content)
  const userName = user?.given_name || null;
  return <article className="home-view">
    <h1 className="home-view__title">
      Hello, <em className="home-view__user-name">{ userName }</em>
    </h1>
    <PostsList />
  </article>;
}
