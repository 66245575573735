import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigation } from './useNavigation';
import { refactorUrl } from './../helpers/url';

/**
 * @param {string} navLinkUrl The url the navlink points to
 * @param {import('react-router').match} match Router match
 * @param {{
 *   pathname: string,
 * }} location Current browser location
 *
 * @returns {boolean} Match or not...
 */
export function isActive(navLinkUrl, match, location) {
  if (!match) {
    return false;
  }

  if (navLinkUrl.split('/').length <= 3) {
    return navLinkUrl === location.pathname;
  }

  return location.pathname.startsWith(navLinkUrl);
}

/**
 * @param {{
 *   topic: string,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export default function SubNavigation({ topic }) {
  /** @type {import('../Callidus').Navitem[]} */
  const navitems = useNavigation(topic);

  return <nav className="sub-navigation">
    <ul className="sub-navigation__list">
      { navitems.map((navitem) => (
        <NavLink
          className="sub-navigation__item"
          key={ navitem.object_id }
          to={ refactorUrl(navitem.url) }
          isActive = { isActive.bind(null, refactorUrl(navitem.url)) }
        >
          { navitem.title }
        </NavLink>
      )) }
    </ul>
  </nav>;
}
