export const API_ROOT = 'https://callidus-dev.pondigital.solutions/wp-json';
export const CORE_API = `${API_ROOT}/wp/v2`;
export const NAV_API = `${API_ROOT}/wp-api-menus/v2`;
export const META_API = `${API_ROOT}/meta/v1`;
export const FRONTEND_URL = 'localhost:3000';
export const BACKEND_URL = 'https://callidus-dev.pondigital.solutions';

export const OKTA_DOMAIN = 'dev-472497.okta.com';
export const OKTA_CLIENT_ID = '0oa1vut05mlbBpvwh357';

export const NAVIGATION_TYPES = {
  'hr': 'contact',
  'callidus': 'news',
};
export const EVENTS_TYPE = 'events';
