import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import PostView from './posts/PostView';
import NavigationView from './navigation/NavigationView';
import PageView from './pages/PageView';
import EventView from './events/EventView';
import { OKTA_CLIENT_ID, OKTA_DOMAIN } from './Constants';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import HomeView from './HomeView';

export const oktaAuth = new OktaAuth({
  issuer: `https://${OKTA_DOMAIN}/oauth2/default`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

/**
 * @param {{
 *   keyCode: number,
 *   ctrlKey: boolean,
 *   target: EventTarget
 * }} arg A basic keydownevent
 */
function detectFormSubmitByShortCut({
  keyCode,
  ctrlKey,
  target,
}) {
  if (ctrlKey && keyCode === 13) {
    const input = /** @type {HTMLFormElement} */ (target);
    input?.form?.querySelector('input[type="submit"]')?.click();
  }
}

/**
 * This is the main entry-point into the app.
 *
 * @returns {JSX.Element} The app container
 */
function App() {
  useEffect(() => {
    document.addEventListener('keydown', detectFormSubmitByShortCut);
  }, []);
  const history = useHistory();

  /**
   * Newer Okta requires you to add your own `restoreOriginalUri` implementation
   * To restore the uri we need `history` and for that we need to be a
   * _descendant_ of <Router>.
   *
   * @param {OktaAuth} _oktaAuth OktaOauth instance passed in by okta
   * @param {string} originalUri URI the user was on when auth was requested
   */
  async function restoreOriginalUri(_oktaAuth, originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
      <div className="App">
        <NavigationView />
        <Switch>
          <Route path='/login/callback' component={ LoginCallback } />
          <SecureRoute path="/post/:slug">
            <PostView />
          </SecureRoute>
          <SecureRoute path="/callidus-events/:slug">
            <EventView />
          </SecureRoute>
          <SecureRoute path="/:topic/:slug">
            <PageView />
          </SecureRoute>
          <SecureRoute path="/:slug">
            <PageView />
          </SecureRoute>
          <SecureRoute path="/" exact>
            <HomeView />
          </SecureRoute>
        </Switch>
      </div>
    </Security>
  );
}

export default App;
