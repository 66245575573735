import { fetchJSONFromAPI } from './api';

const API_ENDPOINT = '/posts';

/**
 * Fetch all posts
 *
 * @returns {Promise<import('../Callidus').SparsePost[]>} resolves to posts.
 */
export async function fetchPosts() {
  return fetchJSONFromAPI(
      API_ENDPOINT,
      {
        '_embed': [
          'wp:featuredmedia',
          'wp:term',
        ].join(','),
        '_fields': [
          'categories',
          'date',
          'excerpt',
          'id',
          'slug',
          'title',
          'type',
          '_links', // This is required to get _embedded props
        ].join(','),
      },
  );
}

/**
 * Fetch a single post by slug
 *
 * @param {string} slug The unique slug of the post
 * @returns {Promise<import('../Callidus').Post>} resolves to posts.
 */
export async function fetchPostBySlug(slug) {
  const posts = await fetchJSONFromAPI(
      API_ENDPOINT,
      {
        '_embed': 'wp:featuredmedia',
        'slug': slug,
      },
  );

  return posts.shift(); // Take the first post of the top
}
