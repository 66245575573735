import React from 'react';
import { useParams } from 'react-router';
import SubNavigation from '../navigation/SubNavigation';
import { NAVIGATION_TYPES } from '../Constants';

/**
 * @param {{
 *   page: import('../Callidus').Page,
 *   children?: JSX.Element | JSX.Element[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function DefaultView({ page, children }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   * @property {string} topic Unique identifier
   */
  const { topic, slug } = useParams();

  return <article className="default-view">
    <h1 dangerouslySetInnerHTML={ {
      __html: page.title?.rendered,
    } }
    className="default-view__title"/>
    <SubNavigation
      topic={ NAVIGATION_TYPES[topic] || NAVIGATION_TYPES[slug] }
    />
    { children || <article dangerouslySetInnerHTML={ {
      __html: page.content?.rendered,
    } }
    className="default-view__content"/> }
  </article>;
}
