/** @typedef {import('../Callidus').SparseEvent} SparseEvent */
import React, { useState } from 'react';
import TimeStamp from '../time/TimeStamp';
import Icon from '../icons/Icon';
import { SpareBaseBlock } from '../posts/SparsePostBlock';
import useInterest from './useInterest';
import { LIKES_CUSTOM_FIELDNAME } from '../dao/likes';

/**
 * @typedef {object} EventProps
 * @property {SparseEvent} post The event to display
 * @property {string} [className] Optional base class-namea
 */

/**
 * Show a listing of a single post
 *
 * @param {EventProps} arg React props
 * @returns {JSX.Element} An `article` representing a single event.
 */
export default function SparseEventBlock({ post, className }) {
  const baseClass = className || 'events-list';
  const [ likesCount, setLikesCount ] = useState(
      post.event_custom_fields[LIKES_CUSTOM_FIELDNAME] || '0',
  );
  const [ isInterested, setInterested ] = useInterest(post);

  const event = {
    categories: [
      8,
    ],
    ...post,
  };

  /**
   * @param {import('react').SyntheticEvent} e Actual mouse-event
   */
  function clickHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    setInterested(!isInterested).then(({ data: { value } }) => {
      setLikesCount(value || 0);
    });
  }

  return (
    <SpareBaseBlock
      key={ event.id }
      className={ baseClass }
      post={ event }
    >
      <TimeStamp
        date={ event.event_custom_fields.callidus_event_date }
        className={ `${baseClass}__timestamp` }
      ><Icon icon="icon-event-schedule" /></TimeStamp>
      <span className={ `${baseClass}__interested-count` }>
        <Icon icon="icon-star" />{ likesCount }
      </span>
      <span className={ `${baseClass}__comment-count` }>
        <Icon icon="icon-chat" />0
      </span>
      <button
        onClick={ clickHandler }
        className={ `${
          baseClass
        }__interested-cta${
          isInterested ? '--interested' : ''
        }` }
      >
        <Icon
          icon={ `icon-${isInterested ? 'checkmark' : 'star'}` }
        />Interested
      </button>
    </SpareBaseBlock>
  );
}
