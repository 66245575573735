import { fetchJSONFromMeta, postJSONToMeta } from './api';

const API_ENDPOINT = '/likes';
export const LIKES_CUSTOM_FIELDNAME = '_cd_like_count';

/**
 * Fetch the replies for a specific post.
 *
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch likes
 * @returns {Promise<any>}
 *          Resolves to the likes
 */
export async function fetchLikesByPost(post) {
  const results = await fetchJSONFromMeta(
      `${API_ENDPOINT}/${[ post.id ]}`,
  );

  const replies = results;

  return replies || []; // Just in case
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to add a like
 * @returns {Promise<any>}
 *          Resolves to the likes
 */
export async function addLikeToPost(post) {
  return postJSONToMeta(
      `${API_ENDPOINT}/${[ post.id ]}`,
  );
}

/**
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to add a like
 * @returns {Promise<any>}
 *          Resolves to the likes
 */
export async function retractLikeFromPost(post) {
  return postJSONToMeta(
      `${API_ENDPOINT}/${[ post.id ]}`,
      '',
      {},
      {
        method: 'DELETE',
      },
  );
}

