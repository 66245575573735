import React from 'react';
import useEvents from '../events/useEvents';
import SparsePostBlock from '../posts/SparsePostBlock';
import usePosts from '../posts/usePosts';
import SparseEventBlock from '../events/SparseEventBlock';
import DefaultView from './DefaultView';
import { EVENTS_TYPE } from '../Constants';
import { useParams } from 'react-router';

const ROOT_CATEGORIES = {
  callidus: 'strategy',
};

/**
 * @param {{
 *   page: import('../Callidus').Page,
 *   children?: JSX.Element | JSX.Element[],
 *   CSSclass?: string,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
function JSXFeed({ children, page, CSSclass }) {
  return <DefaultView page={ page }>
    <nav className={ CSSclass }>
      { children }
    </nav>
  </DefaultView>;
}

/**
 * Return feed of custom events type
 *
 * @param {{
 *   page: import('../Callidus').Page,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
function EventsFeedView({ page }) {
  const [ events ] = useEvents();
  if (events.length <= 0) {
    return null;
  }
  const sortedEvents = events.sort((a, b) => {
    const aDate = String(a.event_custom_fields.callidus_event_date);
    const bDate = String(b.event_custom_fields.callidus_event_date);
    return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
  });
  return <JSXFeed
    page={ page }
    CSSclass="events-list"
  >
    { sortedEvents.map((event) => (
      <SparseEventBlock key={ event.id } post={ event }/>
    )) }
  </JSXFeed>;
}

/**
 * Return feed of general posts type
 *
 * @param {{
 *   page: import('../Callidus').Page,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
function PostsFeedView({ page }) {
  const [ posts ] = usePosts();
  /** @type {{ topic: string, slug: string }} */
  const { topic, slug } = useParams();
  const category = topic ? slug : ROOT_CATEGORIES[slug];
  const filteredPosts = posts.filter((post) =>
    post._embedded['wp:term'][0][0].slug === category);

  return <JSXFeed
    page={ page }
    CSSclass="posts-list"
  >
    { filteredPosts.map((post) => (
      <SparsePostBlock key={ post.id } post={ post }/>
    )) }
  </JSXFeed>;
}

/**
 * @param {{
 *   page: import('../Callidus').Page,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function FeedView({ page }) {
  if (page.slug === EVENTS_TYPE) {
    return <EventsFeedView page={ page }/>;
  }
  return <PostsFeedView page={ page }/>;
}
