import React from 'react';
import { useCategory } from './useCategories';

/**
 * @param {Object} props Category props
 * @param {number} props.uid Category unique id
 *
 * @returns {JSX.Element} The react component
 */
function Category({ uid }) {
  const category = useCategory(uid);
  if (!category) {
    return null;
  }

  return (
    <li className="category-label">{ category.name }</li>
  );
}

/**
 * @param {Object} props CategoriesList props
 * @param {import("../Callidus").SparsePost} props.post
 *        The SparsePost for which to show categories
 * @returns {JSX.Element} The react component
 */
export default function CategoriesList({ post }) {
  if (!post.categories || post.categories.length === 0) {
    return null;
  }
  return (
    <ol className="category-labels">
      { post.categories.map((uid) => (
        <Category uid={ uid } key={ uid } />
      )) }
    </ol>
  );
}
