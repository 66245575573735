/** @typedef {import('../Callidus').SparsePost} SparsePost */
/** @typedef {import('../Callidus').Reply} Reply */
import { fetchJSONFromAPI, postJSONToAPI } from './api';

const API_ENDPOINT = '/comments';

/**
 * Fetch the replies for a specific post.
 *
 * @param {import('../Callidus').SparsePost} post
 *        The post for which to fetch replies
 * @returns {Promise<import('../Callidus').Reply[]>}
 *          Resolves to the array of replies (cleaning up any pre-amble)
 */
export async function fetchRepliesByPost(post) {
  const results = await fetchJSONFromAPI(
      API_ENDPOINT,
      {
        'post': `${post.id}`,
        'per_page': '50',
      },
  );

  const replies = results;

  return replies || []; // Just in case
}

/**
 * @param {string} reply The simple text-string reply
 * @param {SparsePost} post Which post this reply adheres to
 * @param {{
 *   name: string,
 *   email: string,
 * }} user A simple user object
 * @param {Reply} [parent] Which parent this reply adheres to
 * @returns {Promise<object>} See WP-JSON Api for expected content
 */
export async function sendReply(reply, post, user, parent) {
  const data = {
    'author_name': user.name,
    'author_email': user.email,
    'post': post.id,
    'content': reply,
  };

  if (parent) {
    data['parent'] = parent.id;
  }

  return postJSONToAPI(API_ENDPOINT, data);
}
